const langList = [{
    lang: 0, //中文
    // 大厅
    matchingMode: '匹配',
    challenge: '挑战',
    current: '当前卡牌阵容',
    gameRule: '游戏规则',
    rankingList: '排行榜',
    win: "胜",
    negative: "负",
    hall: '大厅',
    store: '商店',
    pets: '宠物',
    cardDeck: '卡组',
    inMatching: '匹配模式中',
    inChallenge: "挑战模式中",
    estimatedTime: "预计匹配时间",
    unmatch: '取消匹配',
    waiting: "等待对手",
    cancel: "取消",
    diamonds100: '挑战将消耗100钻石，三场全胜后可以获得Anome NFT?',
    obtaining: '获取卡牌',
    obtainCards: '当前卡牌数量不足，无法进行游戏，请先前往获取卡牌!',
    automatic: '自动补全',
    toSettings: "前往设置",
    insufficient: "预设卡组卡牌不足",
    enterGame: '进入对局',
    unfinished: '您还有未结束的对局',
    gotIt: "我知道了",
    toPurchase: '前往购买',
    close: '关闭',
    botLink: '请查收bot链接，点击链接加入我们',
    // 对战页面
    enemy: '敌',
    toYour: '到您行动...',
    theOther: "对方行动...",
    reconnecting: "当前网络信号弱, 重连中!",
    trusteeship: '正在智能托管中',
    cancelHosting: '取消托管',
    confirm: '确定',
    ended: "当前对局已结束！",
    //宠物
    obtain: "获得",
    reward: '奖励',
    closeScree: "点击屏幕关闭",
    signIn: "签到",
    welfare: '福利',
    invite: '邀请 ',
    // 商店
    cardbag: '卡包',
    diamond: " 钻石",
    // 挑战胜负弹窗
    victory: '胜利',
    fail: '失败',
    challengeProgress: '当前挑战进度：',
    winningTimes: '获胜3场即可获得抽奖！',
    continueChallenge: '继续挑战',
    winningDraw: '获胜3次即可获得抽奖',
    reChallenge: '重新挑战',
    quit: '退出',
    receive: '去领取',
    // 挑战记录
    challengeTime: '挑战时间',
    achievements: '战绩',
    state: '状态',
    collect: '领取',
    receiving: '领取中',
    failSend: '发送失败',
    beenSent: '已发送',
    web3: '请输入正确的web3钱包地址',
    web3Sign: ' 我们将会在3日之内将资产发放到您的ERC-20地址，您可以在anome.xyz查阅您的资产',
    send: "send",
    destroyed: "本局销毁的卡牌",
    all: '全部',
    //卡包商城
    dailyPack: '每日幸运卡包',
    free: "免费",
    received: "已领取",
    specialBag: "特殊卡包",
    heat: '热',
    open: '打开',
    bagRule1: '可以寻得2位良将',
    bagRule2: '可以寻得3位良将',
    bagRule3: '可以寻得4位良将',
    bagRule4: '可以寻得5位良将有14%概率出金牌战将',
    claimNow: '立即领取',
    Purchasing: "购买中",
    getPackage: '获取卡包：',
    regularBag: "普通卡包",
    intermediatePack: "中级卡包",
    topPackage: '顶级卡包',
    confirm2: '确认',
    purchaseBag: '是否确定购买该卡包?',
    // 邀请
    inviteFriends: '邀请好友',
    withFriends: '与好友',
    fighting: "并肩作战",
    getCash: '得现金',
    goTo: '前往',
    friends: ' 位好友加入游戏',
    goldCoins: '金币',
    boutiqueBag: '精品卡包',
    shareBotMessage: '请查收bot消息，前往点击卡片下方"share"按钮进行分享~',
    // 对战记录
    experience: '经验',
    destruction: '销毁',
    lose: '失去',
    winning: "赢取",
    defeat: '败',
    // 新手礼包
    giftPack: '新手礼包',
    // 胜负弹窗
    getAllCards: "获得对方所有上场卡牌",
    loseAllPlayingCards: "失去所有上场卡牌",
    // 个人中心
    changeNickname: "更改昵称",
    language: "语言",
    preserve: "保存",
    pleaseEnterNickname: "请输入昵称",
    // 个人排行榜
    lastRanking: "上次排名",
    currentRanking: "当前排名",
    // 预设卡组
    myDeck: "我的卡组",
    deployment: '一键上阵',
    kap: '七级及以上卡牌最多两张',
    // 充值
    tonPay: 'TON支付',
    loginTon: "请先登录ton钱包",
    copyAddress: 'Copy address',
    tonDisconnect: 'Disconnect',
    successToast: '充值成功',
    failToast: '充值失败',
    recharge: '充值',
    login: '登录',
    levelCard: '级卡牌',
    // 挑战规则
    signTitle: '挑战规则',
    ruleOne: '1、支付100个钻石参加挑战赛',
    ruleTwo: ' 2、挑战进行三次匹配，全胜后将获取一次抽取锦囊的机会',
    ruleThree: '3、抽取概率为20%获得Anome NFT',
    ruleFour: '4、最终解释权归主办方所有',
    copyMessage: '复制成功',
    // 排行榜
    updateTime: "排行榜更新时间：",
    ranking: '排名',
    user: '用户',
    dj: '等级',
    // 签到
    dailyCheckIn: '每日签到',
    day1: "第一天",
    day2: '第二天',
    day3: '第三天',
    day4: "第四天",
    day5: '第五天',
    day6: '第六天',
    day7: '第七天',
    // 匹配成功过渡页面
    MatchingSuccessful: '匹配成功',
    // 头部导航
    experienceValue: '经验值',
    purchasedMall: '可以在商城购买卡包',
    // 福利
    wlfareTasks: '福利任务',
    myCoins: '我的金币',
    exchangeCash: '兑换现金',
    viewCurrent: '查看当前汇率',
    myCash: '我的现金',
    toWallet: "提现至钱包",
    toDiamond: "转化为钻石",
    dailyTasks: "每日任务",
    oneTimeTask: '一次性任务',
    loginAnomeOnce: "登录anome一次",
    shareAnomeOnce: '分享anome一次',
    onceMatchingBattle: '完成一次匹配对战',
    onceChallengeBattle: '完成一次挑战对战',
    stayTuned: '敬请期待',
    loginDays: '累计登录天数 ',
    complete: '完成 ',
    secondaryMatchingBattle: ' 次匹配对战',
    secondChallengeBattle: ' 次挑战对战',
    day: ' 天',
    crowdingUp: '账户异地登录，登录IP : ',
    rate: '当前汇率',
    consumption: '是否确认消耗 ',
    exchangedGold: ' 金币兑换为 ',
    currentCash: ' 的现金(当前汇率：',
    exchangeFore: ' 的现金兑换为 ',
    goldRate: '金币汇率',
    explain: '汇率说明',
    currentRate: '当前汇率：金币 ',
    rateRule: '汇率规则：',
    rateRule1: '金币转换现金会依据玩家转换现金的数据每天变化，左侧折线图会展示近5天的汇率变化曲线',
    rateRule2: '点击换现金会按照当前汇率将所有金币兑换为现金。兑换的最小比例为$0.01',
    rateRule3: '汇率刷新时间为当日零点',
    joinCommunity: '加入社区',
    startChallengea: '开始挑战',
    abnormal: '异常',
    share: '分享',
    appSign: '请使用竖屏参与游戏对战呦~',
    errMsg: '对局被取消，请重新开始游戏！',
    loginCode: '登录凭据信息获取异常，请重新开启游戏！'
  },
  {
    lang: 1, //英文
    // 大厅
    matchingMode: 'Match',
    challenge: 'Chall.',
    current: 'Current card lineup',
    gameRule: 'Rule',
    rankingList: 'Ranking',
    win: "Win",
    negative: "Loss",
    hall: 'Lobby',
    store: 'Store',
    pets: 'Pets',
    cardDeck: 'Card deck',
    inMatching: 'Match',
    inChallenge: "Chall.",
    estimatedTime: "Est. Time ",
    unmatch: 'Cancel',
    waiting: "Wait",
    cancel: "Cancel",
    diamonds100: 'The challenge will cost 100 diamonds, and after winning all three games, you can earn Anome NFT?',
    obtaining: 'Obtaining',
    obtainCards: 'The current number of cards is insufficient to play the game. Please go to obtain the cards first!',
    automatic: 'Cmpl. Card',
    toSettings: "Settings",
    insufficient: "Insufficient preset deck cards",
    enterGame: 'Enter the game',
    unfinished: 'You still have unfinished matches',
    gotIt: "I got it",
    toPurchase: 'Go to purchase',
    close: 'close',
    botLink: 'Please check the bot link and click on it to join us',
    // 对战页面
    enemy: 'FOE',
    toYour: 'Your Move...',
    theOther: "Opp. Move...",
    reconnecting: "The current network signal is weak, reconnecting",
    trusteeship: 'In smart hosting',
    cancelHosting: 'Cancel',
    confirm: 'confirm',
    ended: "The current match has ended!",
    //宠物
    obtain: "obtain",
    reward: 'reward',
    closeScree: "Click on the screen to close",
    signIn: "Sign in",
    welfare: 'Ben.',
    invite: 'Inv. ',
    // 商店
    cardbag: 'package',
    diamond: " diamond",
    // 挑战胜负弹窗
    victory: 'victory',
    fail: 'fail',
    challengeProgress: 'Curr. Chall. Prog.:',
    winningTimes: 'Winning 3 games will earn you a lucky draw!',
    continueChallenge: 'Cont. Chall.',
    winningDraw: 'Winning 3 times will earn you a lucky draw',
    reChallenge: 'Rtry Ch.',
    quit: 'quit',
    receive: 'receive',
    // 挑战记录
    challengeTime: 'Challenge time',
    achievements: 'Rec',
    state: 'state',
    collect: 'receive',
    receiving: 'Receiving',
    failSend: 'fail in send',
    beenSent: 'has been sent',
    web3: 'Please enter the correct web3 wallet address',
    web3Sign: ' We will distribute the assets to your ERC-20 address within 3 days. You can check your assets on anome.xyz',
    send: "send",
    destroyed: "The cards destroyed by this bureau",
    all: 'whole',
    //卡包商城
    dailyPack: 'Daily Lucky Card Pack',
    free: "free",
    received: "Clmd",
    specialBag: "Special Card Package",
    heat: 'hot',
    open: 'open',
    bagRule1: 'You can find 2 good generals',
    bagRule2: 'You can find 3 good generals',
    bagRule3: 'You can find 4 good generals',
    bagRule4: 'There is a 14% chance of winning a gold medal if 5 good generals can be found',
    claimNow: 'Clm Now',
    Purchasing: "Purchasing",
    getPackage: 'Package:',
    regularBag: "Pck C",
    intermediatePack: "Pck B",
    topPackage: 'Pck A',
    confirm2: 'confirm',
    purchaseBag: 'Are you sure you want to purchase this card package?',
    // 邀请
    inviteFriends: 'Invite Friends',
    withFriends: 'With friends',
    fighting: "Fighting side by side",
    getCash: 'Cash',
    goTo: 'Gt.',
    friends: ' Friends joined the game',
    goldCoins: 'Gold coins',
    boutiqueBag: 'Pck A',
    shareBotMessage: 'Please check the bot message and click on the "share" button below the card to share~',
    // 对战记录
    experience: 'exp.',
    destruction: 'Destr',
    lose: 'lose',
    winning: "Winning",
    defeat: 'Fail',
    // 新手礼包
    giftPack: 'Strt. Pack',
    // 胜负弹窗
    getAllCards: "Obtain all playing cards from the opponent",
    loseAllPlayingCards: "Lose all playing cards",
    // 个人中心
    changeNickname: "Chg. Av.",
    language: "lang.",
    preserve: "preserve",
    pleaseEnterNickname: "Please enter your nickname",
    // 个人排行榜
    lastRanking: "Last Rnk.",
    currentRanking: "Curr. Rnk.",
    // 预设卡组
    myDeck: "My deck",
    deployment: 'Cmpl. Card',
    kap: 'Up to two cards at level 7 or above',
    // 充值
    tonPay: 'TON payment',
    loginTon: "Pls login TON",
    copyAddress: 'Copy address',
    tonDisconnect: 'Disconnect',
    successToast: 'Recharged successfully',
    failToast: 'Recharge failed',
    recharge: 'Wait',
    login: 'Login',
    levelCard: 'Level',
    // 挑战规则
    signTitle: 'Challenge rules',
    ruleOne: '1、Pay 100 diamonds to rarticipate in the challenge',
    ruleTwo: ' 2、Challenge for three ratches, and after a complete victory, rou will receive an opportunity to draw c trophy',
    ruleThree: '3、Extract a probability of 20% to obtain Anome NFT',
    ruleFour: '4、The final interpretation rights belong to the organizer',
    CopyMessage: 'Copy successful',
    // 排行榜
    updateTime: "Update time:",
    ranking: 'ranking',
    user: 'user',
    dj: 'grade',
    // 签到
    dailyCheckIn: 'Daily Bens.',
    day1: "D1",
    day2: 'D2',
    day3: 'D3',
    day4: "D4",
    day5: 'D5',
    day6: 'D6',
    day7: 'D7',
    // 匹配成功过渡页面
    MatchingSuccessful: 'Match successful',
    // 头部导航
    experienceValue: 'Experience value',
    purchasedMall: 'Card packs can be purchased in the mall',
    // 福利
    wlfareTasks: 'Ben. ',
    myCoins: 'My coins',
    exchangeCash: 'Exch. Cash',
    viewCurrent: 'View Curr. Exch. Rate',
    myCash: 'My cash',
    toWallet: "Wd. to Wlt.",
    toDiamond: "Exch. Gems",
    dailyTasks: "Daily tasks",
    oneTimeTask: 'One time task',
    loginAnomeOnce: "Login Once",
    shareAnomeOnce: 'Shr Once ',
    onceMatchingBattle: 'Cmpl. Match Btl.',
    onceChallengeBattle: 'Cmpl. Chall. ',
    stayTuned: 'Stay tuned',
    loginDays: 'Login ',
    complete: 'Cmpl ',
    secondaryMatchingBattle: ' Match',
    secondChallengeBattle: ' Chall.',
    day: ' day',
    crowdingUp: 'Account remote login, login IP: ',
    rate: 'Current exchange rate',
    consumption: 'Confirm consumption ',
    exchangedGold: ' Gold coins are exchanged for ',
    currentCash: ' Cash (current exchange rate:',
    exchangeFore: ' the cash exchange for ',
    goldRate: 'Gold coin exchange rate',
    explain: 'explain',
    currentRate: 'Current exchange rate: gold coins ',
    rateRule: 'Exchange rate rules:',
    rateRule1: 'Exch. Rate Rules: Gold to cash conversion varies daily based on player data. 5-day rate trends shown on left graph.',
    rateRule2: 'Click for gold to cash conversion at current rate. Min. ratio: $0.01.',
    rateRule3: 'The exchange rate refresh time is midnight on the same day',
    joinCommunity: 'Join the Community',
    startChallenge: 'Start Challenge',
    abnormal: 'Abnormal',
    share: 'share',
    appSign: 'Please use vertical screen to participate in game battles~',
    errMsg: 'The game has been cancelled, please restart the game!',
    loginCode: 'Login credentials information obtained abnormally, please restart the game!'
  },
  {
    lang: 2, //俄文
    // 大厅
    matchingMode: 'Матч',
    challenge: 'Выз.',
    current: 'Текущий состав карт',
    gameRule: 'Прав. игры',
    rankingList: 'Рейт.',
    win: "Поб.",
    negative: " Пор.",
    hall: 'Зал',
    store: 'Магазины',
    pets: 'Животные',
    cardDeck: 'Группа карт',
    inMatching: 'Матч',
    inChallenge: "Выз.",
    estimatedTime: "Ож. время",
    unmatch: 'Выз.',
    waiting: "Пдж",
    cancel: "Отменить",
    diamonds100: 'Challenge поглотит 100 алмазов и получит Anome NFT после трех побед.?',
    obtaining: 'Карта',
    obtainCards: 'Текущее количество карт недостаточно, чтобы играть, пожалуйста, сначала идите, чтобы получить карты!',
    automatic: 'Доп. карту',
    toSettings: "Настройки",
    insufficient: "Недостаточный набор карт",
    enterGame: 'Войти в игру',
    unfinished: 'У вас есть незаконченные дела.',
    gotIt: "Я понял.",
    toPurchase: 'Купить',
    close: 'Закрыть',
    botLink: 'Пожалуйста, проверьте ссылку bot и нажмите ссылку, чтобы присоединиться к нам',
    // 对战页面
    enemy: 'враг',
    toYour: 'Ваш ход...',
    theOther: "Ход прот...",
    reconnecting: "Сетевой сигнал слабый, перегруппировка",
    trusteeship: 'Находится в интеллектуальном хостинге.',
    cancelHosting: 'Отменить',
    confirm: 'Определение',
    ended: "Нынешнее противостояние окончено!",
    //宠物
    obtain: "Приобретение",
    reward: 'Награды',
    closeScree: "Нажмите на экран, чтобы закрыть",
    signIn: "Рег.",
    welfare: 'Льг.',
    invite: 'Пригл. ',
    // 商店
    cardbag: 'Пакет',
    diamond: " БР",
    // 挑战胜负弹窗
    victory: 'Победа',
    fail: 'Неудача',
    challengeProgress: 'Тек. прог. выз.:',
    winningTimes: 'За три победы можно получить приз!',
    continueChallenge: 'Прод. выз.',
    winningDraw: 'Трижды можно выиграть приз.',
    reChallenge: 'Повт. в.',
    quit: 'Выход',
    receive: 'Получить',
    // 挑战记录
    challengeTime: 'Время вызова',
    achievements: 'Б. дост.',
    state: 'Статус',
    collect: 'Получать',
    receiving: 'Получается',
    failSend: 'Ошибка отправки',
    beenSent: 'Отправлено',
    web3: 'Введите правильный адрес кошелька web3',
    web3Sign: 'Мы передадим активы на ваш адрес ERC - 20 в течение 3 дней, и вы можете ознакомиться с ними на сайте anome.xyz.',
    send: "Отправить",
    destroyed: "Карточки, уничтоженные Бюро.",
    all: 'Все.',
    //卡包商城
    dailyPack: 'Ежедневный пакет удачи.',
    free: "Бесплатно",
    received: "Пол.",
    specialBag: "Специальные пакеты",
    heat: 'Жара',
    open: 'Открыть',
    bagRule1: 'Можно найти двух хороших людей.',
    bagRule2: 'Можно найти трех хороших людей.',
    bagRule3: 'Можно найти четырех хороших людей.',
    bagRule4: 'Можно найти 5 хороших парней, с 14% вероятностью выиграют золотые медали.',
    claimNow: 'Пол. счс.',
    Purchasing: "В процессе покупки",
    getPackage: 'Пакет:',
    regularBag: "Пак. C",
    intermediatePack: "Пак. B",
    topPackage: 'Пак. A',
    confirm2: 'Подт.',
    purchaseBag: 'Стоит ли покупать пакет?',
    // 邀请
    inviteFriends: 'Пригласить друзей',
    withFriends: '& Друзья',
    fighting: "Сражайтесь бок о бок.",
    getCash: 'нал.',
    goTo: 'Пер.',
    friends: ' Друзья присоединяются к игре',
    goldCoins: 'Зол.',
    boutiqueBag: 'Пак. A',
    shareBotMessage: 'Пожалуйста, проверьте сообщения от ботов и перейдите к нажатию кнопки "share" под карточкой, чтобы поделиться ~',
    // 对战记录
    experience: 'Опыт',
    destruction: 'Унч.',
    lose: 'потер.',
    winning: "Выиграть",
    defeat: 'ПОР',
    // 新手礼包
    giftPack: 'Наб. нов.',
    // 胜负弹窗
    getAllCards: "Получить все карты.",
    loseAllPlayingCards: "Потерял все карты.",
    // 个人中心
    changeNickname: "См. ав.",
    language: "Язык",
    preserve: "Сохранить",
    pleaseEnterNickname: "Введите псевдоним",
    // 个人排行榜
    lastRanking: "Пред. рейт.",
    currentRanking: "Тек. рейт.",
    // 预设卡组
    myDeck: "Моя группа карт.",
    deployment: 'Доп. карту',
    kap: 'Карты класса 7 и выше имеют максимум 2 карты',
    // 充值
    tonPay: 'TON Платить',
    loginTon: "Вх. в TON сн.",
    copyAddress: 'Копирование',
    tonDisconnect: 'Выход',
    successToast: 'Успешное пополнение',
    failToast: 'Ошибка пополнения',
    recharge: 'Пдж.',
    login: 'Вх.',
    levelCard: 'Уровень',
    // 挑战规则
    signTitle: 'Вызов правилам',
    ruleOne: '1、Оплата 100 бриллиантов для rчастия в конкурсе ',
    ruleTwo: ' 2、Вызов - три совпадения, и rосле полной победы вы получите шанс rзять сумку. ',
    ruleThree: '3、Вероятность извлечения 20% для получения Anome NFT ',
    ruleFour: '4、Окончательное толкование принадлежит организатору.',
    CopyMessage: 'Копировать успешно',
    // 排行榜
    updateTime: "ВРТЛ:",
    ranking: 'Рейтинг',
    user: 'Пользователи',
    dj: 'Класс',
    // 签到
    dailyCheckIn: 'Ежед. льг.',
    day1: "1д.",
    day2: '2д.',
    day3: '3д.',
    day4: "4д.",
    day5: '5д.',
    day6: '6д.',
    day7: '7д.',
    // 匹配成功过渡页面
    MatchingSuccessful: 'Успешное совпадение',
    // 头部导航
    experienceValue: 'Значение опыта',
    purchasedMall: 'Купить сумку можно в торговом центре.',
    // 福利
    wlfareTasks: 'Льг.',
    myCoins: 'Мое золото.',
    exchangeCash: 'Обм. нал.',
    viewCurrent: 'Посм. текущ. курс обм.',
    myCash: 'Мои наличные.',
    toWallet: "Выв. на кшл.",
    toDiamond: "Обм. алм.",
    dailyTasks: "Ежзад.",
    oneTimeTask: 'Однозад.',
    loginAnomeOnce: "Вх. одн. раз.",
    shareAnomeOnce: 'Под. одн. раз.',
    onceMatchingBattle: 'Зав. од. матч.',
    onceChallengeBattle: ' Вып. выз.',
    stayTuned: 'С нетерпением жду',
    loginDays: 'Вх. ',
    complete: 'Зав. ',
    secondaryMatchingBattle: ' матч',
    secondChallengeBattle: ' выз.',
    day: ' Боже.',
    crowdingUp: 'Регистрация учетной записи в другом месте, вход в IP: ',
    rate: 'Текущий обменный курс',
    consumption: ' Подтверждение потребления ',
    exchangedGold: ' Золотые монеты обменять на ',
    currentCash: ' Денежная наличность (текущий обменный курс: ',
    exchangeFore: ' Обмен наличными на ',
    goldRate: 'Обменный курс золота',
    explain: 'Прим.',
    currentRate: 'Текущий обменный курс: Золотые монеты ',
    rateRule: 'Правила обменного курса:',
    rateRule1: 'Правила курса: Конвертация золота в наличные изменяется ежедневно, данные игроков. График 5-дневного курса слева.',
    rateRule2: 'Клик для конвертации золота в нал. по текущему курсу. Мин. соотн.: $0.01.',
    rateRule3: 'Обменный курс обновляется до нуля дня',
    joinCommunity: 'Сообщество',
    startChallengea: 'Начать вызов',
    abnormal: 'Необычные',
    share: 'Поделиться',
    appSign: 'Пожалуйста, используйте вертикальный экран для участия в игре.~',
    errMsg: 'Паровая игра отменена, пожалуйста, начните игру заново!',
    loginCode: 'Регистрационные учетные данные для получения аномалии, пожалуйста, перезапустите игру!'
  },
];

export default langList