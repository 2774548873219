import {
    subscribe,
    publish,
    cancel
} from "../eventbus"
import {
    Client
} from '@stomp/stompjs';

let socketMsgQueue = []
let client = null;
let connectIndex = 0;

const websocketInit = () => {
    console.log(client, "zendo");
    if (client) {
        console.log("当前已登录，就不重新走了");
        return;
    }

    connectIndex = connectIndex + 1
    console.log('websocket重新链接-------------第：', connectIndex, "次");
    const authorization = encodeURIComponent(localStorage.getItem('authorization'))
    // debugger
    const wxUrl = process.env.VUE_APP_API_SOCKET_URL

    client = new Client({
        brokerURL: `${wxUrl}?authorization=${authorization}`,
        onConnect: () => {
            console.log("client被创建：", "zendo")
            publish('webSocketNewLink', {
                "websocketIsLink": true,
            })
            publish('newLink', {})
            socketMsgQueue = []
            subEvent(client)
            topicSubscribe({
                send: (destination, headers, body) => {
                    if (client.connected) {
                        send(destination, headers, body);
                    } else {
                        socketMsgQueue.push(body)
                    }
                }
            });
        },
        onWebSocketError: () => {
            console.log("连接断开", arguments);
            webSocketClose()
        },
        debug: console.log,
        discardWebsocketOnCommFailure: true,
        heartbeatOutgoing: 1000,
        heartbeatIncoming: 1000,
        reconnectDelay: 1000
    });

    client.activate();
}

function send(topic, headers, body) {
    client.publish({
        destination: topic,
        headers: headers,
        body: body
    })
}

const topicSubscribe = (client) => {
    // 场景加载回复确认
    subscribe('confirm', 'game', function (content) {
        console.log(content);
        const message = content
        if (content.roomNumber) {
            send('/user/topic/loadingSceneSuccessful', {}, JSON.stringify({
                "id": parseInt(new Date().getTime() / 1000),
                "code": 0,
                "timestamp": parseInt(new Date().getTime() / 1000),
                "data": message
            }))
            console.log(message, 'loadingSceneSuccessful============');
            console.log(JSON.parse(localStorage.getItem('userInfo')).sessionId, 'loadingSceneSuccessful============sessionId');
        }
    })
    // 放置卡牌位置
    subscribe('placingCards', 'game', function (content) {
        console.log(content);
        const message = content
        if (content.cardId) {
            send('/user/topic/userPutCard', {}, JSON.stringify({
                "id": parseInt(new Date().getTime() / 1000),
                "code": 0,
                "timestamp": parseInt(new Date().getTime() / 1000),
                "data": message
            }))


        }
    })
    //  智能托管/取消托管
    subscribe('userTrusteeship', 'game', function (content) {
        console.log(content, '托管+++++++++++');
        const message = content
        if (content.status) {
            send('/user/topic/userTrusteeship', {}, JSON.stringify({
                "id": parseInt(new Date().getTime() / 1000),
                "code": 0,
                "timestamp": parseInt(new Date().getTime() / 1000),
                "data": message
            }))
        }
    })
    // 对局取消回复
    subscribe('interruptReplay', 'game', function (content) {
        const message = {}
        send('/user/topic/interrupt_reply', {}, JSON.stringify({
            "id": parseInt(new Date().getTime() / 1000),
            "code": 0,
            "timestamp": parseInt(new Date().getTime() / 1000),
            "data": message
        }))
        console.log('对局取消回复+++++++++');
    })
    // 对方是否断线回复
    subscribe('onOffLineReplay', 'game', function (content) {
        const message = {}
        // send('/user/topic/userTrusteeship', message)
        send('/user/topic/interrupt_reply', {}, JSON.stringify({
            "id": parseInt(new Date().getTime() / 1000),
            "code": 0,
            "timestamp": parseInt(new Date().getTime() / 1000),
            "data": message
        }))
        console.log('对方是否断线回复+++++++++');
    })
    // 房间信息维护回复 1. 通知房主、玩家加入房间；2. 通知房主玩家已准备、未准备；3. 房主归属变化
    subscribe('sysUserDefaultRoomReplay', 'game', function (content) {
        const message = {}
        // send('/user/topic/userTrusteeship', message)
        send('/user/topic/sysUserDefaultRoom_reply', {}, JSON.stringify({
            "id": parseInt(new Date().getTime() / 1000),
            "code": 0,
            "timestamp": parseInt(new Date().getTime() / 1000),
            "data": message
        }))
        console.log('房间信息维护回复+++++++++');
    })
}

const subEvent = (client) => {
    // a. 场景加载 
    client.subscribe('/user/topic/loadingScene', function (body) {
        console.log('场景加载:', body);
        publish('loadingScene', body.body)
    })
    //  a. 匹配成功服务端告知开始游戏信息
    client.subscribe('/user/topic/startGame', function (body) {
        console.log(parseInt(new Date().getTime() / 1000), body.body, '匹配成功服务端告知开始游戏信息');
        publish('startGame', body.body)
    })
    // a. 服务端发送卡牌放置信息
    client.subscribe('/user/topic/putCard', function (body) {
        console.log(parseInt(new Date().getTime() / 1000), body.body, '服务端发送卡牌放置信息');
        publish('putCard', body.body)
    })
    // a. 服务端发送卡牌翻转信息
    client.subscribe('/user/topic/turnCard', function (body) {
        console.log(parseInt(new Date().getTime() / 1000), body.body, '服务端发送卡牌翻转信息');
        publish('turnCard', body.body)
    })
    // 服务端发送本轮结束下一位准备信息
    client.subscribe('/user/topic/turnEnd', function (body) {
        console.log(parseInt(new Date().getTime() / 1000), body.body, '服务端发送本轮结束下一位准备信息');
        publish('turnEnd', body.body)
    })
    // 服务端发送游戏结束胜负结果信息
    client.subscribe('/user/topic/gameOver', function (body) {
        console.log(parseInt(new Date().getTime() / 1000), body.body, '服务端发送游戏结束胜负结果信息');
        publish('gameOver', body.body)
    })
    // 异常报错
    client.subscribe('/user/topic/gameError', function (body) {
        console.log('异常报错:', body);
        publish('gameError', body.body)
    })
    // 服务端发起托管/取消托管
    client.subscribe('/user/topic/sysTrusteeship', function (body) {
        console.log(parseInt(new Date().getTime() / 1000), body.body, '服务端发起托管/取消托管');
        publish('sysTrusteeship', body.body)
    })
    // 对局被取消/未进入
    client.subscribe('/user/topic/interrupt', function (body) {
        console.log(parseInt(new Date().getTime() / 1000), body.body, '对局被取消/未进入');
        localStorage.setItem('showRematch', true)
        publish('interrupt', body.body)
    })
    // 对方是否断线
    client.subscribe('/user/topic/onOffLine', function (body) {
        console.log(parseInt(new Date().getTime() / 1000), body.body, '对方是否断线');
        publish('onOffLine', body.body)
    })
    // 房间信息维护 1. 通知房主、玩家加入房间；2. 通知房主玩家已准备、未准备；3. 房主归属变化
    client.subscribe('/user/topic/sysUserDefaultRoom', function (body) {
        console.log(parseInt(new Date().getTime() / 1000), body.body, '房间信息维护');
        publish('sysUserDefaultRoom', body.body)
    })
    // 充值成功提醒,发起充值时可以监听:rechargeRecordSuccess
    client.subscribe('/user/topic/rechargeRecordSuccess', function (body) {
        console.log(parseInt(new Date().getTime() / 1000), body.body, '充值成功提醒');
        publish('rechargeRecordSuccess', body.body)
    })
    // 远程登录警告
    client.subscribe('/user/topic/remoteLoginWarn', function (body) {
        console.log(parseInt(new Date().getTime() / 1000), body.body, '远程登录警告');
        publish('remoteLoginWarn', body.body)
    })
}

const webSocketClose = () => {
    // if (!isConnect) return
    console.log('webSocketClose====断开链接');
    publish('webSocketNewLink', {
        "websocketIsLink": false,
    });
    cancel('webSocketMsg', this)
}

export {
    websocketInit,
    webSocketClose,
}