// 路由守卫及权限管理
// import router from '@/router/config-router'
import router from '@/router/config-router'
import Vue from 'vue';
import App from './App.vue';
// 守卫
// console.log(router,'router====');
router.beforeEach((to, from, next) => {
    if (to.path !== '/' && to.path !== '/game' && to.path !== '/hall') {
        console.log(to.query, 'to......');
        router.replace({
            path: "/",
            query: to.query
        })
        next()
    } else {
        console.log('11111111111', to.query);
        const button = document.querySelector('.particle-pwe-btn .particle-pwe-down-arrow')
        const particle_iframe = document.querySelector('.particle-pwe-iframe');
        if (particle_iframe) {
            const src = particle_iframe.src
            particle_iframe.src = src
            setTimeout(() => {
                button.click()
            }, 500);
        }
        next()
    }
})