var eventsInstance = new Map()
/**
 * 消息订阅
 * key：消息标识
 * target：消息发起者，用来区分相同key不同的消息
 * callback：回调函数
 */
function subscribe(key, target, callback) {
    //消息对象
    var event = {
        'target': target,
        'callback': callback
    }
    //先通过key拿到对应的消息队列
    var value = eventsInstance.get(key)
    //当前key已存在消息队列说明是不同页面相同的key的消息订阅
    if (Array.isArray(value)) {
        //过滤出消息发起者不同的消息，相当于覆盖key和target都一样的消息
        const newList = value.filter(function (item) {
            return item.target != target
        })
        //过滤出的队列重新插入此次订阅的消息
        newList.push(event)
        eventsInstance.set(key, newList)
    } else { //不是队列表示字典中没有包含当前key的消息，直接插入
        eventsInstance.set(key, [event])
    }
    console.log('function sub ', eventsInstance)
}
/**
 * 消息发布
 * key：消息标识
 * data：回调数据
 */
function publish(key, data) {
    var value = eventsInstance.get(key)
    //如果队列存在则遍历队列，然后调用消息发起者的回调函数，并将data数据进行回调
    if (Array.isArray(value)) {
        value.map(function (e) {
            var target = e.target
            var callback = e.callback
            callback.call(target, data)
        })
    }
}
/**
 * 取消订阅
 * key：消息标识
 * target：消息发起者，用来区分相同key不同的消息
 */
function cancel(key, target) {
    var haskey = eventsInstance.has(key)
    //是否存在此消息队列
    if (haskey) {
        var value = eventsInstance.get(key)
        if (Array.isArray(value)) {
            //如果队列中只有一条数据直接删除
            if (value.length == 1) {
                eventsInstance.delete(key)
            } else {
                //如果队列中存在多条数据则过滤出和当前取消订阅target不同的消息然后重新设置到key的消息队列中
                value = value.filter(function (e) {
                    return e.target != target
                })
                eventsInstance.set(key, value)
            }
        }
    }
}

module.exports = {
    subscribe,
    publish,
    cancel,
    eventsInstance
}