import {
  createStore
} from 'vuex'
import langList from '../utils/lang_map'
import langStore from './lang'

// 创建一个新的 store 实例
export default createStore({
  state() {
    return {
      language: {},
      subLanguage: {},
    }
  },
  mutations: {
    changeLanguage(state, type) {
      state.language = langList[type]
      state.subLanguage = langList[type == 0 ? 1 : 0]
      // console.log(langList, 'langListlangList');
    },
  },
  getters: {
    getLanguage: (state) => state.language
  },
  modules:{
    langStore
  }
})