import langList from '../utils/lang_map'

const langStore = {
  state: {
    language: {},
    navigatorList: [],
    count: 0
  },
  mutations: {
    changeLanguage(state, type) {
      console.log(111, state, type);
      state.language = langList[type]
      console.log(state.language, 'state.language=tjj');
      // localStorage.setItem("language", JSON.stringify(state.language));
    },
    increment(state) {
      console.log('111', state);
      state.count++
    },
    decrement(state) {
      state.count--
    }
  },
  actions: {
    increment(context, plyload) {
      console.log(context, 'context', plyload);
      context.commit('changeLanguage', plyload)
    },
    decrement(context) {
      context.commit('decrement')
    }
  },
  getters: {
    getCount(state) {
      return state.count
    }
  }
}

export default langStore