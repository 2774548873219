function loadFacebookPixel(id) {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  window.fbq('init', id); // Replace 'your-pixel-id' with your actual Facebook Pixel ID
  window.fbq('track', 'PageView');
}

export function useFacebookPixel() {
  return {
    init(pixelId) {
      loadFacebookPixel(pixelId);
    },
    trackEvent(eventName, eventData = {}) {
      window.fbq('track', eventName, eventData);
    }
  };
}