<!--
 * @Author: dyx
 * @Date: 2023-08-16 10:22:59
 * @LastEditTime: 2023-08-16 10:49:15
 * @LastEditors: dyx
 * @Description: 
 * @FilePath: /libawall-anome-metaverse/src/App.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div>
    <router-view />
    <div v-if="isLandscape" class="computer">
      <div
        class="content"
        :style="{ marginTop: (clientHeight - 30) / 2 + 'px' }"
      >
       {{language.appSign}}
      </div>
    </div>
    <div v-if="crowdingUp" class="computer">
      <div
        class="content"
        :style="{ marginTop: (clientHeight - 30) / 2 + 'px' }"
      >
        {{ language.crowdingUp }}{{ loginIp }}
      </div>
    </div>
  </div>
</template>
<script>
import { subscribe, eventsInstance } from "./utils/eventbus";
import { webSocketClose } from "./utils/webSocket/ws";
export default {
  components: {},
  data() {
    return {
      isLandscape: false,
      clientHeight: 0,
      otherEnvironment: false,
      crowdingUp: false, //远程登录警告
      // language: {},
      loginIp: "",
    };
  },
  computed: {
    language() {
      return this.$store.state.langStore.language;
    },
  },
  mounted() {
    // document.addEventListener("click", function (event) {
    //   // 检查点击的元素是否是你想要监听的按钮
    //   console.log(event.target,'event.target.matches====tjj');
    //   if (event.target.matches(".your-button-class")) {
    //     // 在这里执行你的逻辑
    //     console.log("用户点击了按钮");
    //   }
    // });
    this.languageType = JSON.parse(localStorage.getItem("languageType"));
    this.$store.commit("changeLanguage", this.languageType);
    subscribe("remoteLoginWarn", "home", (content) => {
      let data = JSON.parse(content);
      console.log("远程登录警告app:", data);
      // this.language = JSON.parse(localStorage.getItem("language"));
      this.crowdingUp = true;
      this.loginIp = data.data.remoteLoginIp;
      webSocketClose();
    });
    window.addEventListener("resize", this.handleOrientationChange);
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const aspectRatio = (windowWidth / windowHeight).toFixed(2);
    const heightRatio = (windowHeight / 900).toFixed(2);
    localStorage.setItem("scaleRatio", heightRatio);
    localStorage.setItem("aspectRatio", aspectRatio);
    // console.log(aspectRatio, "windowHeight=====");
    console.log(heightRatio, "scaleRatio=========");
    console.log(aspectRatio, "aspectRatio=======");
    this.$nextTick(() => {
      // 监听页面点击事件
      // document.addEventListener("click", this.playMusic);
    });
    this.clientHeight = document.documentElement.clientHeight;
    this.determine();
  },
  methods: {
    playMusic() {
      // 创建音频对象
      const musicList = JSON.parse(localStorage.getItem("musicList"));
      musicList.forEach((item) => {
        if (item.type === 1) {
          this.allMusic = item.fileUrl;
        }
      });
      const overallContext = new Audio(this.allMusic);
      // 自动播放音乐
      overallContext.play();
      // 监听音乐播放结束的事件
      overallContext.addEventListener("ended", this.onMusicEnded);
      // 移除点击事件监听，确保只播放一次音乐
      document.removeEventListener("click", this.playMusic);
    },
    onMusicEnded() {
      // 重新播放音乐
      this.playMusic();
    },
    handleOrientationChange() {
      // 判断手机屏幕方向
      // console.log(window.orientation, "判断手机屏幕方向");
      const isLandscape = Math.abs(window.orientation) === 90;
      if (isLandscape) {
        // 横屏逻辑
        // console.log("横屏打开isMobile===");
        this.isLandscape = true;
      } else {
        // 竖屏逻辑
        // console.log("竖屏打开isMobile===");
        this.isLandscape = false;
      }
    },
    handleResize() {
      const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      // const heightRatio = (screenHeight / 900).toFixed(2);
      // const widthRatio = (screenWidth / 350).toFixed(2);
      const heightRatio = (screenHeight / 724).toFixed(2);
      const widthRatio = (screenWidth / 375).toFixed(2);
      if (heightRatio > widthRatio) {
        localStorage.setItem("scaleRatio", widthRatio);
      } else {
        localStorage.setItem("scaleRatio", heightRatio);
      }
    },
    determine() {
      var isWechat = /micromessenger/i.test(navigator.userAgent.toLowerCase());
      const tgHref = location.href;
      var tgWebAppPlatform = /tgWebAppPlatform/i.test(tgHref);
      var tgWebAppVersion = /tgWebAppVersion/i.test(tgHref);
      const isTelegram = localStorage.getItem("isTelegram");
      console.log(tgWebAppPlatform, tgWebAppVersion, "tgWebAppVersion");
      if (isWechat) {
        localStorage.setItem("isTelegram", 0);
        this.otherEnvironment = false;
      } else if ((tgWebAppPlatform && tgWebAppVersion) || isTelegram) {
        localStorage.setItem("isTelegram", 1);
        this.otherEnvironment = false;
      } else {
        // 敬请期待
        this.otherEnvironment = true;
        localStorage.setItem("isTelegram", 0);
        console.log("======在其他环境中打开项目======");
      }
    },
  },
};
</script>
<style lang="scss">
@function rem($px) {
  @return $px / 75 * 1rem;
}
html,
body {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.computer {
  position: fixed;
  top: 0;
  width: rem(750);
  height: 100%;
  background-color: #000;
  z-index: 10000;
  word-break: break-all;
  .content {
    width: rem(700);
    padding: 0 rem(30);
    text-align: center;
    font-size: 20px;
    color: #fff;
    display: flex;
    height: 140px;
    flex-wrap: wrap;
    word-break: break-all;
  }
}
#app {
  width: rem(750);
  height: 100%;
  background-color: #fff;
}
</style>
