import {
    createRouter,
    createWebHistory,
    createWebHashHistory
} from 'vue-router'

// 配置路由
const routes = [{
        path: '/',
        name: 'home',
        component: () => import( /* webpackChunkName: "login" */ '@/views/login.vue'),
    },
    // {
    //     path: '/home',
    //     component: () => import( /* webpackChunkName: "home" */ '@/views/home.vue')
    // },
    {
        path: '/game',
        name: 'game',
        component: () => import( /* webpackChunkName: "game" */ '@/views/game.vue')
    },
    // {
    //     path: '/myCards',
    //     component: () => import( /* webpackChunkName: "myCards" */ '@/views/myCards.vue')
    // },
    {
        path: '/hall',
        name: 'hall',
        component: () => import( /* webpackChunkName: "hall" */ '@/views/hall.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router